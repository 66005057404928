<template>
  <div class="map-page-footer" ref="modalBox">
    <div class="map-page-footer-header" @mousedown="dragEvent($event)">
      <h5>轨迹明细</h5>
      <a-icon type="close" @click="handleMapTrackDetailClose" />
    </div>
    <div class="map-page-footer-content" ref="contentBox">
      <a-table :columns="columns" :data-source="historyTrackPointsArray" :pagination="pagination" :customRow="handleTrackRowclick">
        <span slot="operation" slot-scope="text, record" v-if="isHaveOperation">
          <footer-tool ref="footerRef" :objectInfo="record" :withBorder="false"/>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import FooterTool from '../../mapInfoWindow/footerTool'
export default {
  name: 'MapTrackDetail',
  components: { FooterTool },
  props: {
    show: {
      type: Boolean,
      default: () => {}
    },
    moduleKey: {
      type: String,
      default: () => {}
    },
    historyTrackPointsArray: {
      type: Array,
      default: () => {}
    }
  },
  data() {
    return {
      marginSize: 8,
      isHaveOperation: false,
      columns: [
        {
          title: '定位时间',
          dataIndex: 'gpsTime',
          key: 'gpsTime',
          customRender: (text, record, index) => {
            const time = record.gpsTime || record.deviceTime || record.time
            if (time && time.length > 23) {
              return this.parseDateTime(new Date(time), 'yyyy-MM-dd HH:mm:ss')
            }
            return time
          }
        },
        {
          title: '经度/伟度',
          dataIndex: 'lng',
          key: 'lng',
          customRender(text, record, index) {
            return record.lng.toFixed(6) + '/' + record.lat.toFixed(6)
          }
        },
        {
          title: '作业速度',
          dataIndex: 'speed',
          key: 'speed',
          customRender(text, record, index) {
            return record.speed ? Number(record.speed).toFixed(2) + 'km/h' : '暂无速度'
          }
        }
      ],
      pagination: {
        total: 0,
        pageSize: 100, // 每页中显示100条数据
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据` // 分页中显示总的数据
      }
    }
  },
  mounted() {
    this.setColumns()
  },
  methods: {
    setColumns() {
      if (this.moduleKey === 'traditionalVehicle') {
        this.isHaveOperation = true
        this.columns.push({
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        })
      }
    },
    getDomNode() {
      const documentElement = document.documentElement
      const bodyElem = document.body
      return {
        scrollTop: documentElement.scrollTop || bodyElem.scrollTop,
        scrollLeft: documentElement.scrollLeft || bodyElem.scrollLeft,
        visibleHeight: documentElement.clientHeight || bodyElem.clientHeight,
        visibleWidth: documentElement.clientWidth || bodyElem.clientWidth
      }
    },
    handleMapTrackDetailClose() {
      this.$emit('onClose', true)
    },
    handleTrackRowclick(record, index) {
      return {
        on: {
          click: () => {
            record.time = this.parseDateTime(new Date(record.time), 'yyyy-MM-dd HH:mm:ss')
            this.$emit('onTrackRowClick', record)
          }
        }
      }
    },
    getBox() {
      return this.$refs.modalBox
    },
    dragEvent(evnt) {
      evnt.preventDefault()
      console.log('dragEvent')
      const { $listeners, marginSize, events = {}, remember, storage } = this
      const { visibleHeight } = this.getDomNode()
      const minHeight = 0
      const maxHeight = visibleHeight - 20
      const modalBoxElem = this.getBox()
      const contentBoxElem = this.$refs.contentBox
      const demMousemove = document.onmousemove
      const demMouseup = document.onmouseup
      const clientHeight = modalBoxElem.clientHeight
      const disY = evnt.clientY
      const offsetTop = modalBoxElem.offsetTop
      // const offsetLeft = modalBoxElem.offsetLeft
      const params = { type: 'resize', $modal: this }
      document.onmousemove = (evnt) => {
        evnt.preventDefault()
        let dragTop = 0
        let height = 0
        dragTop = disY - evnt.clientY
        height = clientHeight + dragTop
        if (offsetTop - dragTop > marginSize) {
          if (height > minHeight) {
            const h = height < maxHeight ? height : maxHeight
            modalBoxElem.style.height = `${h}px`
            contentBoxElem.style.height = `${h - 64}px`
          }
        }

        modalBoxElem.className = modalBoxElem.className.replace(/\s?is--drag/, '') + ' is--drag'

        if (remember && storage) {
          this.savePosStorage()
        }
        if ($listeners.zoom) {
          this.$emit('zoom', params)
        } else if (events.zoom) {
          events.zoom.call(this, params)
        }
      }
      document.onmouseup = () => {
        this.zoomLocat = null
        document.onmousemove = demMousemove
        document.onmouseup = demMouseup
        setTimeout(() => {
          modalBoxElem.className = modalBoxElem.className.replace(/\s?is--drag/, '')
        }, 50)
      }
    }
  }
}
</script>
<style lang="less">
.map-page-footer {
  position: absolute;
  z-index: 100 !important;
  min-height: 200px;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  border-top: 1px solid @borderColor;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  background-color: @default-bg-color;
  pointer-events: auto;

  .map-page-footer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1 px solid #ddd;
    background-color: @default-bg-color;
    // background-image: -webkit-linear-gradient(#f1f5fd, #e9f0ff);
    // background-image: -moz-linear-gradient(#f1f5fd, #e9f0ff);
    background-image: @main-Line-color;
    box-shadow: inset 0 1px 0 #fff;
    cursor: s-resize;
    h5 {
      padding: 10px;
      margin-bottom: 0;
    }
    i {
      padding-right: 10px;
      cursor: pointer;
      color: @primary-font-color;
    }
  }
  .map-page-footer-content {
    min-height: 200px;
    height: 200px;
    overflow-y: auto;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 6px 16px !important;
  }
}
</style>

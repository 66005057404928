var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "modalBox", staticClass: "map-page-footer" }, [
    _c(
      "div",
      {
        staticClass: "map-page-footer-header",
        on: {
          mousedown: function ($event) {
            return _vm.dragEvent($event)
          },
        },
      },
      [
        _c("h5", [_vm._v("轨迹明细")]),
        _c("a-icon", {
          attrs: { type: "close" },
          on: { click: _vm.handleMapTrackDetailClose },
        }),
      ],
      1
    ),
    _c(
      "div",
      { ref: "contentBox", staticClass: "map-page-footer-content" },
      [
        _c("a-table", {
          attrs: {
            columns: _vm.columns,
            "data-source": _vm.historyTrackPointsArray,
            pagination: _vm.pagination,
            customRow: _vm.handleTrackRowclick,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "operation",
                fn: function (text, record) {
                  return _vm.isHaveOperation
                    ? _c(
                        "span",
                        {},
                        [
                          _c("footer-tool", {
                            ref: "footerRef",
                            attrs: { objectInfo: record, withBorder: false },
                          }),
                        ],
                        1
                      )
                    : _vm._e()
                },
              },
            ],
            null,
            true
          ),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }